<template>
  <b-row class="d-flex justify-content-center align-items-center h-100-vh">
    <b-col sm="8" md="6" lg="4">
      <div class="text-center mb-4">
        <img
          src="/assets/images/logo-baru.png"
          style="max-width: 260px !important"
          alt=""
        />
      </div>
      <b-card>
        <div class="flex-between-center">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <form-group-input
                placeholder="Username anda"
                :need-label="false"
                label="Email"
                v-model="model.username"
                rules="required"
                id="username"
              />
              <div class="mt-3 w-100"></div>
              <form-group-input
                placeholder="Password anda"
                :need-label="false"
                label="Email"
                v-model="model.password"
                rules="required"
                type="password"
                id="password"
              />
              <!-- <b-row class="flex-between-center">
                <b-col cols="auto">
                  <a class="fs--1" href="#">Forgot Password?</a>
                </b-col>
              </b-row> -->
              <div class="text-center" >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  class="w-100 my-2"
                  ><font-awesome-icon
                    icon="spinner"
                    spin
                    v-if="isLoading"
                  ></font-awesome-icon>
                  Log in</b-button
                >

                <a
                  
                  href="http://petugas.ear-institute.id/mobile/home"
                  style="display: flex; align-items: center;justify-content: center;"
                  class="text-decoration-none"
                >
                <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc. --><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"/></svg>
                  Beralih Versi Mobile
                </a>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-card>

      <div class="text-center my-3"></div>
    </b-col>
  </b-row>
</template>

<script>
import FormGroupInput from "@/components/FormGroupInput.vue";
export default {
  components: {
    FormGroupInput,
  },
  data() {
    return {
      isLoading: false,
      model: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.isLoading) {
        return false;
      }
      this.isLoading = true;
      this.$store
        .dispatch("account/login", {
          username: this.model.username,
          password: this.model.password,
        })
        .then(() => {
          // this.$store.dispatch('notification/welcome', this.$store.getters['account/accountData'].firstName)
          this.isLoading = false;
          this.$router
            .push(
              this.$route.query.redirect !== undefined &&
                !this.$route.query.redirect.includes("logout")
                ? this.$route.query.redirect
                : "/"
            )
            .then(() => {
              // location.reload()
            });
        })
        .catch((error) => {
          this.isLoading = false;
          // console.log(serializeErrorMessage)
          this.$store.dispatch("notification/error", error);
          // this.$store.dispatch('notification/error', 'Kombinasi email & password anda salah')
        });
    },
  },
};
</script>